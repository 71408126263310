import React, { Fragment, useEffect, useMemo } from 'react'
import cx from 'classnames'
import { getTransportTypeInfo, useForecast, useSchedules, useStation } from 'models/publicTransport'
import dayjs from 'date'

import { Icon, RouteIcon, RouteIconMini, Text } from 'components/dataDisplay'
import { ActionButton } from 'components/inputs'
import { Bone } from 'components/layout'
import { Tooltip } from 'components/feedback'


type StationInfoProps = {
  className?: string
  stationId: string
  closeModal?: () => void
}

const StationInfo: React.FC<StationInfoProps> = (props) => {
  const { className, stationId, closeModal } = props

  const { station, isFetching: isStationFetching } = useStation(stationId)
  const { forecast, isForecastFetching, fetch } = useForecast(stationId)

  const { title, lat, lon } = station || {}

  const stationForecast = useMemo(() => forecast?.filter(({ forecasts }) => forecasts.length), [ forecast ])

  const [ year, month, day ] = dayjs().format('YYYY.MM.DD').split('.')
  const { schedules, isFetching: isScheduleFetching } = useSchedules({ year, month, day, stationId, full: false })

  const activeRoutes = useMemo(() => {
    return schedules?.routes?.filter(({ route: { active } }) => active)
  }, [ schedules?.routes ])

  useEffect(() => {
    const timer = setInterval(() => {
      fetch(true)
    }, 3000)

    return () => clearInterval(timer)
  }, [ fetch ])

  return (
    <div className={className}>
      {
        closeModal &&
          <div className="flex">
            <Text
              size="h16-20"
              message={station ? title : ''}
              color="titanic"
            />
            <ActionButton
              className="ml-auto"
              color="rocky"
              icon="action/close_16"
              onClick={closeModal}
            />
          </div>
      }
      <Text
        className="opacity-72"
        size="s13-r"
        message="Автобусная остановка"
        color="titanic"
      />
      {
        isForecastFetching ? (
          <>
            <Bone className="mt-24px" w={90} h={20} />
            <Bone className="mt-12px" w={90} h={24} />
            <Bone className="mt-24px" w={90} h={20} />
            <Bone className="mt-12px" w={70} h={24} />
            <Bone className="mt-24px" w={200} h={20} />
            <Bone className="mt-8px" w={200} h={32} />
            <Bone className="mt-16px" w={200} h={32} />
          </>
        ) : (
          <>
            {
              Boolean(stationForecast.length) && (
                <>
                  <div className="flex items-center mt-24px">
                    <Text
                      message="Онлайн прогноз прибытия"
                      size="t16-20"
                      color="titanic"
                    />
                    <Tooltip
                      message="Местоположение транспорта отслеживается в реальном времени"
                      position="top-right"
                    >
                      <Icon
                        className="ml-8px opacity-32"
                        name="main/info_16"
                        color="rocky"
                      />
                    </Tooltip>
                  </div>
                  {
                    stationForecast.map(({ route, forecasts }, index) => {
                      const mins = Number((forecasts[forecasts.length - 1].seconds / 60).toFixed(0))
                      return (
                        <div className={cx('flex items-center', index ? 'mt-8px' : 'mt-16px')} key={index}>
                          <RouteIcon
                            title={route.title}
                            transportType={route.type.title}
                          />
                          <Text
                            className="ml-12px"
                            message={mins >= 1 ? `${mins} минут` : 'уже прибывает'}
                            size="c16"
                            color="matrix"
                          />
                        </div>
                      )
                    })
                  }
                </>
              )
            }
            {
              isScheduleFetching ? (
                <>
                  <Bone className="mt-24px" w={350} h={20} />
                  <Bone className="mt-12px" w={120} h={32} />
                  <Bone className="mt-16px" w={120} h={32} />
                  <Bone className="mt-16px" w={120} h={32} />
                  <Bone className="mt-16px" w={120} h={32} />
                  <Bone className="mt-16px" w={120} h={32} />
                </>
              ) : (
                <>
                  {
                    Boolean(activeRoutes?.length) && (
                      <Text
                        className="mt-24px"
                        message="Плановое расписание всего транспорта"
                        size="t16-20"
                        color="titanic"
                      />
                    )
                  }
                  {
                    activeRoutes?.map(({ route: { title, type: { title: typeTag } }, stations }, routeIndex) => {
                      const arrivalTime = stations.find(({ station: { id } }, index) => String(id) === stationId)?.arrivals?.[0]

                      return arrivalTime && (
                        <div className={cx('flex', routeIndex ? 'mt-16px' : 'mt-12px')} key={routeIndex}>
                          <div className="no-shrink">
                            <RouteIcon
                              title={title}
                              transportType={typeTag}
                            />
                          </div>
                          <div className="flex flex-wrap ml-6px mt-8px g-8px">
                            <Text
                              message={`в ${arrivalTime}`}
                              size="t16-20"
                              color="titanic"
                            />
                          </div>
                        </div>
                      )
                    })
                  }
                </>
              )
            }
          </>
        )
      }
    </div>
  )
}

export default StationInfo