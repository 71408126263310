import { useMemo } from 'react'
import { useQuery } from 'hooks'


type Props = {
  skip?: boolean
  year: string
  month: string
  day: string
  full?: boolean
} & ({ routeId?: string } | { stationId?: string })

const useSchedules = (props: Props) => {
  const { skip, year, month, day, full } = props
  const routeId = 'routeId' in props ? props.routeId : null
  const stationId = 'stationId' in props ? props.stationId : null

  const queryOptions = useMemo(() => ({
    url: `/transport/rest/bus/schedule?date=${year}-${month}-${day}${routeId ? `&rId=${routeId}` : ''}${stationId ? `&sId=${stationId}` : ''}&full=${full ? 'true' : 'false'}`,
    skip: skip,
  }), [ year, month, day, routeId, stationId, full, skip ])

  const { data: schedules, errors: schedulesErrors,
    isFetching: isSchedulesFetching } = useQuery<PublicTransport.Schedules>(queryOptions)

  return {
    schedules,
    errors: schedulesErrors,
    isFetching: isSchedulesFetching,
  }
}


export default useSchedules